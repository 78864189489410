<template>
  <div v-if="medals && medals.length" class="card mb-4 card-sidebar p-0">
    <h2 class="title w-full pb-2">Conquistas</h2>
    <div
      v-for="medal in medals"
      :key="medal.processId"
      class="mb-2"
      style="min-height: 100px"
    >
      <div class="flex h-full">
        <div
          v-if="medal.urls"
          class="relative"
          :style="`min-width: ${100 + 30 * (medal.urls.length - 1)}px`"
        >
          <ShowUserImage
            v-for="(medalUrl, index) in medal.urls"
            :key="medalUrl"
            :url="medalUrl"
            img-alt="Conquista"
            width="100"
            height="100"
            class="absolute"
            :style="`left: ${30 * index}px`"
          />
        </div>
        <div
          v-if="medal.count > 1"
          class="font-bold relative text-3xl text-blue-600"
          style="left: -45px; top: 50px"
        >
          {{ medal.count }}X
        </div>
        <div class="ml-2 font-bold">
          <p class="mt-0 mb-1 text-lg">Investidor {{ medal.description }}</p>
          <router-link
            v-if="medal.count == 1"
            :to="{ name: 'process', params: { id: medal.processId } }"
          >
            <p class="my-0 text-blue-600">
              {{ medal.processTypeName }} na cidade de {{ medal.city }}
            </p>
          </router-link>
          <p v-else class="my-0 text-blue-600">
            {{ medal.processTypeName }} na cidade de {{ medal.city }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  components: { ShowUserImage },
  data() {
    return {
      medals: [],
      isLoading: false,
    };
  },
  created() {
    this.getClientMedals();
  },
  methods: {
    getClientMedals() {
      this.isLoading = true;
      this.$root
        .apiGet(this.$root.api.clientMedals)
        .then((response) => {
          this.isLoading = false;
          this.medals = response.data.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>
